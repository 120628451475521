.App {
	text-align: center;
}

body {
	margin: 0;
	background: #505050;
	height: 100%;
	overflow: hidden;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

.layout {
	flex: 1;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.preview-content {
	display: flex;
	flex-direction: column;
	align-items: center;
}

#container {
	position: relative;
	overflow: hidden;
	width: 100vw;
	height: 100vh;
	perspective: 2000px;
}

.room {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100vw;
	height: 100vh;
	margin: -50vh 0 0 -50vw;
	pointer-events: none;
	opacity: 0;
	transform-style: preserve-3d;
}

.room--current {
	pointer-events: auto;
	opacity: 1;
}

.room__side {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	transform-style: preserve-3d;
}

.room__side--left,
.room__side--right {
	width: 1800px;
	height: 100vh;
}
@media (max-width: 576px) {
	.room__side--left,
	.room__side--right {
		width: 0px;
		height: 0vh;
	}
}

.room__side--back {
	width: 100vw;
	height: 100vh;
	transform: translate3d(0, 0, -1800px) rotate3d(1, 0, 0, 0.1deg);
	box-shadow: 0 0 0 2px silver;
}
@media (max-width: 576px) {
	.room__side--back {
		width: 100vw;
		height: 100vh;
		transform: translate3d(0, 0, -6px) rotate3d(1, 0, 0, 0.1deg);
	}
}

.room__side--right {
	right: 0;
	justify-content: flex-end;
	transform: rotate3d(0, 1, 0, -90.04deg);
	transform-origin: 100% 50%;
	box-shadow: 0 0 0 2px silver;
}

.room__side--left {
	justify-content: flex-start;
	transform: rotate3d(0, 1, 0, 90.05deg);
	transform-origin: 0 50%;
	box-shadow: 0 0 0 2px silver;
}

.room__side--bottom {
	width: 100vw; /* depth */
	height: 4000px;
	background: #d9d4ce;
	transform: rotate3d(1, 0, 0, 90deg) translate3d(0, -4000px, 0);
	transform-origin: 50% 0%;
}

.room__side--bottom {
	top: 100%;
}
@media (max-width: 576px) {
	.room__side--bottom {
		width: 100vw;
		height: 4000px;
		background: #d0d0d0;
		transform: rotate3d(1, 0, 0, 0deg) translate3d(0, -120px, 0);
		transform-origin: 50% 0%;
	}
}

.room__side--Top {
	width: 100vw; /* depth */
	height: 4000px;
	transform: rotate3d(1, 0, 0, 90deg) translate3d(0, -4000px, 0);
	transform-origin: 50% 0%;
	background-color: #ffffff;
	background: linear-gradient(164deg, #d9d4ce 18%, #ffff 66%);
}
.frameName {
	margin: 0px auto;
	padding: 4px;
}

.room__img {
	flex: none;
	max-width: 40%;
	max-height: 60%;
	margin: 0 5%;
	transform: translate3d(0, 0, 10px);
	backface-visibility: hidden;
}

.benchWrapper {
	margin: 0px auto;
	position: relative;
	top: 74%;
}

@media (max-width: 576px) {
	.benchWrapper {
		margin: 0px auto;
		position: relative;
		top: 82%;
	}
}

.Frame_type {
	font-size: 10px;
	font-family: sans-serif;
}

.frames_layout {
	text-align: center;
	color: gray;
	padding: 40px;
}

.overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	display: flex;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 1;
}

#overlay_Content {
	text-align: left;
	width: 40%;
	margin: 0px auto;
	position: relative;
	top: 12%;
}

@media (max-width: 576px) {
	#overlay_Content {
		text-align: left;
		width: 90%;
		margin: 0px auto;
		position: relative;
		top: 12%;
	}
}

.closeBtn {
	position: absolute;
	top: 45px;
	right: 0px;
}
.mb-1 {
	margin-bottom: -0.5rem !important;
}
.mt-1 {
	margin-top: 0.25rem !important;
}
.mt-2 {
	margin-top: 0.5rem !important;
}
.mt-3 {
	margin-top: 1rem !important;
}
.portrait_shadow {
	-webkit-box-shadow: 12px 10px 17px -8px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 12px 10px 17px -8px rgba(0, 0, 0, 0.75);
	box-shadow: 12px 10px 17px -8px rgba(0, 0, 0, 0.75);
}
.matFrame_shadow {
	-webkit-box-shadow: 13px 9px 19px 2px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 13px 9px 19px 2px rgba(0, 0, 0, 0.75);
	box-shadow: 13px 9px 19px 2px rgba(0, 0, 0, 0.75);
}

p {
	font-size: 13px !important;
}

i {
	font-size: 13px !important;
}
#clone {
	width: 100%;
}
.portraitWrapper {
	top: 40%;
	left: 50%;
	position: fixed;
	transform: translate(-50%, -50%);
}

@media (max-width: 630px) {
	.portraitWrapper {
		top: 65%;
		left: 50%;
		position: fixed;
		transform: translate(-50%, -50%);
	}
}

.CouchWrapper {
	margin: 0px auto;
	position: absolute;
	bottom: -45px;
}

@media (max-width: 576px) {
	.CouchWrapper {
		margin: 0px auto;
		position: absolute;
		bottom: -14px;
	}
}

.couchDimension {
	width: 100vw;
	height: 25vw;
}

@media (max-width: 630px) {
	.couchDimension {
		width: 100vw;
		height: 32vw;
	}
}

.colorDenotation {
	box-sizing: border-box;
	width: 10px;
	height: 10px;
	border: 2px solid black;
}
.stockDenotation {
	height: 10px;
	width: 10px;
	background-color: red;
	border-radius: 50%;
}
